import { Feature, Product } from '../types';

type GetFeatureRule = (
  feature: Feature['data']
) => (product: Product['data']) => boolean;

const FEATURE_RULES = {
  greaterThan: (productValue: string, ruleValue: string) =>
    parseInt(productValue) > parseInt(ruleValue),
  lessThan: (productValue: string, ruleValue: string) =>
    parseInt(productValue) < parseInt(ruleValue),
  Exists: (a) => a === 'YES',
  Equals: (a, b) => a == b,
};

export const getFeatureRule: GetFeatureRule = ({
  specification,
  rule,
  ruleValue,
}) => {
  const featureRule = FEATURE_RULES[rule];

  if (!featureRule) {
    return () => false;
  }

  return (product) => {
    const productValue = product[specification];

    if (!productValue) {
      return false;
    }

    return featureRule(productValue, ruleValue);
  };
};

import { Link } from 'gatsby';
import React from 'react';
import { Features, Product } from '../../types';
import { getFeatureRule } from '../../utils/features';

interface Props {
  features: Features;
  product: Product;
}

const ProductFeaturesWidget: React.FC<Props> = ({ features, product }) => {
  const featuresList = features.nodes
    .filter((feature) => {
      const featureRule = getFeatureRule(feature.data);
      return featureRule(product.data);
    })
    .map((feature) => (
      <li key={feature.data.slug}>
        <Link
          className="block px-2 py-2 hover:underline"
          to={`/${feature.data.slug}/`}
        >
          <h5 className="text-sm">{feature.data.name}</h5>
        </Link>
      </li>
    ));

  if (featuresList.length === 0) return null;

  return (
    <div className="w-full bg-white rounded-lg p-7 mb-7">
      <div className="before:bg-main p-0 pb-3 mb-4 relative flex items-center justify-between border-b border-gray before:absolute before:-bottom-0.5 before:left-0 before:h-0.5 before:w-12">
        <h5 className="mb-0 font-semibold uppercase">Related features</h5>
      </div>
      <ul>{featuresList}</ul>
    </div>
  );
};

export default ProductFeaturesWidget;

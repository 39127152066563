import React, { useState } from 'react';
import '../../styles/imageSlider.css';

interface Props {
  images: string;
  productName: string;
}

const ProductImageSlider: React.FC<Props> = ({ images, productName }) => {
  const [selectedImage, setSelectedImage] = useState<string>('id0');

  if (!images) {
    return <img src="/images/icon.png" alt="" width="300" className="m-auto" />;
  }

  const productImages = images.split(',');

  if (productImages.length === 1) {
    return (
      <div>
        <img src={`/images/${productImages[0].trim()}`} alt={productName} />
      </div>
    );
  }

  const slides = productImages.map((image, i) => (
    <React.Fragment key={i}>
      <input
        checked={selectedImage === `id${i}`}
        id={`id${i}`}
        name="ani"
        type="radio"
        readOnly
      />
      <label htmlFor={`id${i}`}>
        <img
          src={`/images/${image.trim()}`}
          alt={productName}
          width="100"
          onClick={() => setSelectedImage(`id${i}`)}
        />
      </label>
      <img src={`/images/${image.trim()}`} alt={productName} />
    </React.Fragment>
  ));
  return (
    <section>
      <div className="wrapper">
        <div className="boxes">
          <div className="slider">{slides}</div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(ProductImageSlider);

import React from 'react';
import { Products, Specs } from '../../types';

interface Props {
  specs: Specs;
  variants: Products;
}

const ProductFeaturedSpecs: React.FC<Props> = ({ specs, variants }) => {
  if (specs.nodes.length === 0) return null;

  const featuredSpecs = Object.values(
    specs.nodes.reduce((c, { data: { source, name } }) => {
      c[source] = c[source] || { source, name, value: [] };
      c[source].value = c[source].value
        .concat([
          ...new Set(variants.nodes.map((variant) => variant.data[source])),
        ])
        .filter((n) => n)
        .join(', ');
      return c;
    }, [])
  );

  const productSpecs = featuredSpecs
    .filter((spec) => spec.value)
    .map((spec) => (
      <li
        key={spec.source}
        className="flex items-center justify-between bg-gray-100 w-full py-3 px-3.5 rounded-lg capitalize mb-3 last:mb-0"
      >
        <h6>{spec.name}</h6>
        <p className="text-right">{spec.value}</p>
      </li>
    ))
    .splice(0, 4);

  return (
    <div>
      <ul>{productSpecs}</ul>
    </div>
  );
};

export default React.memo(ProductFeaturedSpecs);

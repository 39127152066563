import React from 'react';
import { Link } from 'gatsby';
import { Brand, Items } from '../../types';
import useSiteMetadata from '../../hooks/useSiteMetadata';

interface Props {
  brands: Items<Brand>;
}

const BrandsList: React.FC<Props> = ({ brands }) => {
  const { siteSubject } = useSiteMetadata();

  if (brands.nodes.length === 0) return null;

  const brandsList = brands.nodes.map((brand) => (
    <li
      key={brand.data.slug}
      className="px-2 transition border-b hover:bg-gray-100"
    >
      <Link
        to={`/${brand.data.slug}/`}
        className="block py-3 text-xl font-bold"
      >
        {brand.data.name}
      </Link>
    </li>
  ));

  return (
    <section className="container pb-28">
      <h2 className="pb-10 text-center">
        {siteSubject} by <span className="text-main">Brand</span>
      </h2>
      <ul className="grid grid-flow-row grid-cols-1 gap-3 md:grid-cols-3">
        {brandsList}
      </ul>
    </section>
  );
};

export default BrandsList;

import React from 'react';
import { Review } from '../../types';
import { StarIcon } from '@heroicons/react/solid';

interface Props {
  item: Review;
}

const ProductReviewItem: React.FC<Props> = ({ item }) => (
  <div className="px-5 py-3 mb-6 bg-gray-100 border rounded-lg md:py-7 md:px-11">
    <div className="pb-3">
      <h6 className="truncate">{item.data.title}</h6>
      <div className="pb-2 text-xs">{item.data.site}</div>
      {item.data.rating !== 0 && (
        <ul className="flex items-center text-yellow-400">
          {[...Array(Math.floor(item.data.rating))].map((_, index) => (
            <li key={index}>
              <StarIcon className="inline w-5 h-5" />
            </li>
          ))}
          <li>
            <h5 className="text-sm">- {item.data.rating}</h5>
          </li>
        </ul>
      )}
    </div>
    {item.data.summary && (
      <div className="line-clamp-3">{item.data.summary}</div>
    )}
    <a
      style={{ color: '#007bff' }}
      className="text-sm font-medium text-blue hover:underline"
      href={item.data.url}
      target="_blank"
      rel="nofollow"
    >
      Read Full Review Here
    </a>
  </div>
);

export default ProductReviewItem;

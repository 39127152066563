import React from 'react';
import { Link } from 'gatsby';
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  ChevronDoubleLeftIcon,
} from '@heroicons/react/outline';

import { getPaginationState } from '../utils/pagination';

const Pagination = ({ pageContext }) => {
  const pagination = getPaginationState(pageContext);
  return (
    <ul className="flex items-center justify-center">
      {!pagination.isFirst && (
        <>
          <li className="mx-1">
            <Link
              className="block w-10 h-10 font-medium leading-10 text-center text-gray-500 transition rounded-full hover:bg-main hover:text-white"
              to={pagination.base}
            >
              <ChevronDoubleLeftIcon className="p-3" />
            </Link>
          </li>
          <li className="mx-1">
            <Link
              className="block w-10 h-10 font-medium leading-10 text-center text-gray-500 transition rounded-full hover:bg-main hover:text-white"
              to={pagination.prevPage}
              rel="prev"
            >
              <ChevronLeftIcon className="p-3" />
            </Link>
          </li>
          <li className="mx-1">
            <Link
              className="block w-10 h-10 font-medium leading-10 text-center text-gray-500 transition rounded-full hover:bg-main hover:text-white"
              to={pagination.prevPage}
            >
              {pagination.prevNumPage}
            </Link>
          </li>
        </>
      )}
      <li className="items-center mx-1">
        <span className="block w-10 h-10 font-medium leading-10 text-center text-white rounded-full bg-main">
          {pagination.currentPage}
        </span>
      </li>
      {!pagination.isLast && (
        <li className="mx-1">
          <Link
            className="block w-10 h-10 font-medium leading-10 text-center text-gray-500 transition rounded-full hover:bg-main hover:text-white"
            to={pagination.nextPage}
          >
            {pagination.nextNumPage}
          </Link>
        </li>
      )}
      {pagination.isFirst && pagination.numPages > 2 && (
        <li className="mx-1">
          <Link
            className="block w-10 h-10 font-medium leading-10 text-center text-gray-500 transition rounded-full hover:bg-main hover:text-white"
            to={`${pagination.base}${pagination.page}${pagination.nextNextNumPage}/`}
          >
            {pagination.nextNextNumPage}
          </Link>
        </li>
      )}
      {!pagination.isLast && (
        <li className="mx-1">
          <Link
            className="block w-10 h-10 font-medium leading-10 text-center text-gray-500 transition rounded-full hover:bg-main hover:text-white"
            to={pagination.nextPage}
            rel="next"
          >
            <ChevronRightIcon className="p-3" />
          </Link>
        </li>
      )}
    </ul>
  );
};

export default Pagination;

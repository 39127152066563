import React from 'react';
import { Link } from 'gatsby';
import { EyeIcon } from '@heroicons/react/solid';

interface Props {
  title: string;
  description: string;
  link: string;
  linkName: string;
}

const Hero: React.FC<Props> = ({ title, description, link, linkName }) => (
  <section className="relative z-10 pt-16 pb-24 bg-opacity-50 bg-center bg-no-repeat bg-cover md:pt-24 bg-hero md:pb-36 bg-hero-color">
    <div className="container">
      <div className="text-center text-white">
        <h1 className="pb-5">{title}</h1>
        <p className="text-lg pb-14">{description}</p>
        <Link
          to={link}
          className="py-4 text-sm font-medium uppercase transition bg-white border-2 rounded-lg hover:bg-main hover:text-white px-9 text-main border-main"
        >
          <EyeIcon className="inline w-6 h-6 pr-2" />
          <span>{linkName}</span>
        </Link>
      </div>
    </div>
  </section>
);

export default Hero;

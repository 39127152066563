import React from 'react';
import { Product, Specs } from '../../types';
import { findObjectSimilarity, getUniqueProducts } from '../../utils/common';
import ProductCard from './ProductCard';
import { pick } from 'lodash';

function getRelatedProducts(
  products: Product[],
  featuredSpecs: Specs,
  compareWithProduct: Product,
  uniqueProducts: Product[],
  { maxResults = 10 }: { maxResults?: number } = {}
) {
  // find simmilarty with only featured keys
  const featuredSpecsProps = featuredSpecs.nodes.map(
    (spec) => spec.data.source
  );

  return getUniqueProducts(products)
    .filter((product) => product.data.slug !== compareWithProduct.data.slug)
    .filter(
      (product) =>
        !uniqueProducts.some(
          (uniqueProduct) => product.data.slug === uniqueProduct.data.slug
        )
    )
    .map((product) => ({
      ...product,
      similarity: findObjectSimilarity(
        pick(product.data, featuredSpecsProps),
        pick(compareWithProduct.data, featuredSpecsProps)
      ),
    }))
    .sort((a, b) => b.similarity - a.similarity)
    .slice(0, maxResults)
    .map(({ similarity, ...product }) => product);
}

interface Props {
  title: string;
  products: Product[];
  currentProduct: Product;
  featuredSpecs: Specs;
}

const ProductsList: React.FC<Props> = ({
  title,
  products,
  currentProduct,
  featuredSpecs,
}) => {
  if (products.length <= 1) return null;
  const sameBrandProductsList = products.filter(
    (product) => product.data.brand === currentProduct.data.brand
  );
  const uniqueProducts = getUniqueProducts(sameBrandProductsList).splice(0, 5);
  const productsList = uniqueProducts.map((product) => (
    <div key={product.data.slug}>
      <ProductCard product={product} size="small" />
    </div>
  ));

  const productsListAcrossBands = getRelatedProducts(
    products,
    featuredSpecs,
    currentProduct,
    uniqueProducts
  ).map((product) => (
    <div key={product.data.slug}>
      <ProductCard product={product} size="small" hideProductImage />
    </div>
  ));

  return (
    <section className="container pb-14 md:pb-24">
      <h2 className="text-center pb-11">
        {title} <span className="text-main">{currentProduct.data.name}</span>
      </h2>
      <div className="grid grid-flow-row grid-cols-2 gap-4 md:grid-cols-4 lg:grid-cols-5 sm:grid-cols-3">
        {productsList}
        {productsListAcrossBands}
      </div>
    </section>
  );
};

export default ProductsList;

import { default as handlebarsInstance } from 'handlebars';

handlebarsInstance.registerHelper(
  'ifContainsMore',
  function (value, findIn, options) {
    return findIn?.split(',').some((part) => value?.includes(part))
      ? options.fn(this)
      : options.inverse(this);
  }
);

handlebarsInstance.registerHelper(
  'ifContains',
  function (findIn, value, options) {
    return findIn?.includes(value) ? options.fn(this) : options.inverse(this);
  }
);

handlebarsInstance.registerHelper('ifEquals', function (arg1, arg2, options) {
  return arg1 === arg2 ? options.fn(this) : options.inverse(this);
});

handlebarsInstance.registerHelper(
  'ifGreaterThan',
  function (propertyValue, value, options) {
    return propertyValue > parseInt(value)
      ? options.fn(this)
      : options.inverse(this);
  }
);

handlebarsInstance.registerHelper(
  'ifLessThan',
  function (propertyValue, value, options) {
    return propertyValue < parseInt(value)
      ? options.fn(this)
      : options.inverse(this);
  }
);

handlebarsInstance.registerHelper(
  'ifBetween',
  function (value, findIn, options) {
    if (!findIn) {
      return options.inverse(this);
    }

    const [from, to] = findIn?.split(',');

    if (!from || !to) {
      return options.inverse(this);
    }

    const propertyValue = parseInt(value);

    return propertyValue >= from && propertyValue <= to
      ? options.fn(this)
      : options.inverse(this);
  }
);

export default handlebarsInstance;

import React from 'react';
import { Link, PageProps } from 'gatsby';

interface Breadcrumb {
  pathname: string;
  crumbLabel: string;
}

interface Props {
  category: string;
  brand: string;
  home?: boolean;
  breadcrumb?: ReadonlyArray<Breadcrumb>;
}

const Breadcrumbs: React.FC<Props> = ({
  breadcrumb,
  home,
  category,
  brand,
}) => {
  const elements = [];

  if (home) {
    elements.push({ pathname: '/', crumbLabel: 'Home' });
  }

  if (category) {
    elements.push({
      pathname: `/${category.replace(/\s/g, '-').toLowerCase()}/`,
      crumbLabel: category,
    });
  }

  if (brand) {
    elements.push({
      pathname: `/${brand.replace(/\s/g, '-').toLowerCase()}/`,
      crumbLabel: brand,
    });
  }

  breadcrumb?.forEach((item) => {
    elements.push({
      pathname: `/${item.pathname}`,
      crumbLabel: item.crumbLabel,
    });
  });

  const list = elements.map((value, index) => (
    <li key={index} className="capitalize breadcrumb-item">
      <Link key={index} to={value.pathname}>
        {value.crumbLabel}
      </Link>
    </li>
  ));

  return <ol className="flex items-center text-xs">{list}</ol>;
};

export default Breadcrumbs;

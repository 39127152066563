import React from 'react';

interface Props {
  title: string;
  children: React.ReactNode;
}

const ReviewsContainer: React.FC<Props> = ({ title, children }) => (
  <div className="read-more w-full bg-white rounded-lg p-7 mb-7">
    <div className="before:bg-main p-0 pb-3 mb-4 relative flex bg-transparent border-b border-gray before:absolute before:-bottom-0.5 before:left-0 before:h-0.5 before:w-12">
      <h2 className="mb-0 font-semibold uppercase">{title}</h2>
    </div>
    {children}
  </div>
);

export default ReviewsContainer;

import { graphql, useStaticQuery } from 'gatsby';

interface StaticubMenu {
  name: string;
  slug: string;
}
interface Navigation {
  name: string;
  slug: string;
  submenu: string;
  staticSubMenu: StaticubMenu[];
  isCustomPage: true;
}

interface UseSiteMetadataProps {
  site: {
    siteMetadata: {
      siteTitle: string;
      siteDescription: string;
      siteUrl: string;
      siteSubject: string;
      siteSubjectSlug: string;
      siteLanguage: string;
      siteImage: string;
      siteNavigation: Navigation[];
      [key: string]: unknown;
    };
  };
}

const useSiteMetadata = () => {
  const data = useStaticQuery<UseSiteMetadataProps>(graphql`
    query {
      site {
        siteMetadata {
          siteTitle
          siteDescription
          siteUrl
          siteSubject
          siteSubjectSlug
          siteLanguage
          siteImage
          siteNavigation {
            name
            slug
            submenu
            isCustomPage
            staticSubMenu {
              name
              slug
            }
          }
        }
      }
    }
  `);

  return data.site.siteMetadata;
};

export default useSiteMetadata;

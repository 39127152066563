import React from 'react';

interface Props {
  src: string;
  title: string;
}

const convertToEmbedLink = (src: string) => {
  const youtubeId = src.split('?v=')[1];
  return youtubeId ? `https://www.youtube.com/embed/${youtubeId}` : src;
};

const YoutubePlayer: React.FC<Props> = ({ src, title }) => {
  return (
    <iframe
      width="560"
      height="315"
      src={src.includes('embed') ? src : convertToEmbedLink(src)}
      title={title}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
};

export default YoutubePlayer;

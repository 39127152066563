import React from 'react';
import { Products } from '../../types';
import ProductCard from './ProductCard';

interface Props {
  products: Products;
}

const ProductsLatest: React.FC<Props> = ({ products }) => {
  if (products.nodes.length === 0) return null;

  const productsList = products.nodes.map((product, index) => (
    <div key={index}>
      <ProductCard product={product} />
    </div>
  ));

  return (
    <section className="container pt-10 md:pt-28 pb-28">
      <h2 className="pb-10 text-center">
        Latest <span className="text-main">Products</span>
      </h2>
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:gap-7 ">
        {productsList}
      </div>
    </section>
  );
};

export default ProductsLatest;

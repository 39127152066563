import React from 'react';
import { Link } from 'gatsby';

import { BlogPost } from '../../types';

interface Props {
  item: BlogPost;
}

const BlogCard: React.FC<Props> = ({ item }) => (
  <Link
    to={`/blog/${item.data.slug}/`}
    className="block overflow-hidden transition bg-gray-100 border rounded-lg shadow-xl hover:shadow-lg hover:border-main mb-7 border-gray p-5 h-full"
  >
    <h4 className="pb-2">{item.data.title}</h4>
    <p>{item.data.shortDescription}</p>
  </Link>
);

export default BlogCard;

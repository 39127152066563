import React from 'react';
import { Purchases } from '../../types';
import { formatPrice } from '../../utils/formatPrice';

interface Props {
  purchases: Purchases;
  productName: string;
}

const ProductPurchaseSummary: React.FC<Props> = ({
  productName,
  purchases,
}) => {
  // Items are ordered by GraphQL request
  const purchasesWithPrice = purchases.nodes.filter(
    (item) => !!item.data.totalPrice
  );
  const lowestPrice = purchasesWithPrice[0].data.totalPrice;
  const lowestPriceSite = purchasesWithPrice[0].data.site;
  const countOfPurchases = purchases.nodes.length;
  const countOfPurchasesWithPrice = purchasesWithPrice.length;
  const hasMultiplePurchases = countOfPurchasesWithPrice > 1;
  const allPricesEqual = purchasesWithPrice.every(
    (item) => item.data.totalPrice === purchasesWithPrice[0].data.totalPrice
  );
  const averagePrice =
    purchasesWithPrice.reduce((sum, item) => sum + item.data.totalPrice, 0) /
    countOfPurchasesWithPrice;

  return (
    <div className="pb-6">
      <p className="text-base font-normal pb-2">
        {!hasMultiplePurchases && lowestPrice && lowestPriceSite ? (
          <>
            The lowest price is <strong>${lowestPrice}</strong> for the{' '}
            {productName}, available from <strong>{lowestPriceSite}</strong>.{' '}
          </>
        ) : null}
        {hasMultiplePurchases && lowestPrice ? (
          <>
            The lowest price is <strong>${lowestPrice}</strong> for the{' '}
            <strong>{productName}</strong>, available from multiple sites.{' '}
          </>
        ) : null}
        {!allPricesEqual && hasMultiplePurchases ? (
          <>
            The <strong>{countOfPurchases}</strong> purchase links we have
            analyzed have revealed an average price of{' '}
            <strong>{formatPrice(averagePrice)}</strong>.
          </>
        ) : null}
      </p>
    </div>
  );
};

export default ProductPurchaseSummary;

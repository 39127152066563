import {
  FilteredDynamicContent,
  Products,
  DynamicContents,
} from '../../../types';
import handlebarsInstance from '../../../utils/handlebarsInstance';

export default function generateDynamicContentForVariants(
  dynamicContents: DynamicContents,
  variants: Products
): FilteredDynamicContent {
  /* 
    The app is not yet ready to handle multiple field values in one sentence .
    Get fields, which starts with "multiMatch<field>", and collect their fields in an array for later exclude

    This needs to be developed as a feature later on
   */
  const fieldsToRemove = dynamicContents.nodes.reduce(
    (previousValue: string[], dynamicContent) => {
      if (dynamicContent.data.field.startsWith('multiMatch')) {
        const field = dynamicContent.data.field.slice(11, -1);

        if (!previousValue.includes(field)) {
          previousValue.push(field);
        }
      }

      return previousValue;
    },
    []
  );

  // Filter out the collected "fieldsToRemove"
  return dynamicContents.nodes
    .filter(
      (dynamicContent) =>
        !fieldsToRemove.includes(dynamicContent.data.field) &&
        !dynamicContent.data.field.startsWith('multiMatch')
    )
    .reduce((previousValue, dynamicContent) => {
      if (!dynamicContent.data.rule) {
        return previousValue;
      }

      variants.nodes.forEach((item) => {
        let compiledText = '';

        try {
          const template = handlebarsInstance.compile(dynamicContent.data.rule);
          compiledText = template(item.data);
        } catch (err) {
          compiledText = '';
        }

        if (!compiledText) {
          return previousValue;
        }

        if (!previousValue[dynamicContent.data.section]) {
          previousValue[dynamicContent.data.section] = [];
        }

        if (previousValue[dynamicContent.data.section].includes(compiledText)) {
          return previousValue;
        }

        previousValue[dynamicContent.data.section].push(compiledText);
      });

      return previousValue;
    }, {});
}

import React from 'react';
import { MailIcon } from '@heroicons/react/solid';
import useSiteMetadata from '../hooks/useSiteMetadata';
import { Link } from 'gatsby';
import useGetCustomPages from '../hooks/useGetCustomPages';

export const Footer: React.FC = () => {
  const { siteTitle } = useSiteMetadata();
  const { isCustomPageExists } = useGetCustomPages();

  const currentYear = new Date().getFullYear();

  return (
    <footer className="text-white pt-14 md:pt-24 bg-darkerMain">
      {/* <div className="container grid grid-cols-1 gap-5 pb-16 md:grid-cols-2">
      <div>
        <h2 className="pb-6 ">Subscribe for Latest Offers</h2>
        <p className="text-gray-500">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laboriosam,
          aliquid reiciendis! Exercitationem soluta provident non.
        </p>
      </div>
      <div className="pt-6">
        <form className="relative flex items-center ">
          <input
            className="w-full py-5 pl-5 pr-48 text-gray-700 rounded-lg"
            type="text"
            placeholder="Enter Your Email Address"
          />
          <button className="absolute px-8 py-4 font-medium uppercase transition rounded-lg right-1 bg-main hover:bg-darkMain">
            <MailIcon className="inline w-6 h-6 pr-2" />
            <span>Subscribe</span>
          </button>
        </form>
      </div>
    </div> */}
      <div className="p-8 bg-darkMain">
        <div className="container text-center text-white">
          <p>
            Copyright {currentYear} &copy; {siteTitle}. All Rights Reserved.{' '}
            {isCustomPageExists('terms') && <Link to="/terms">Terms. </Link>}
            {isCustomPageExists('privacy') && (
              <Link to="/privacy">Privacy.</Link>
            )}
          </p>
        </div>
      </div>
    </footer>
  );
};

import React from 'react';
import { Reviews } from '../../types';
import ProgressBar from '../ProgressBar';
import Rating, { FullStar } from '../Rating';

interface Props {
  reviews: Reviews;
}

const ReviewsSummary: React.FC<Props> = ({ reviews }) => {
  const countOfReviews = reviews.nodes.length;
  const sumRating =
    reviews.nodes.reduce((accumulator, a) => accumulator + a.data.rating, 0) /
    countOfReviews;

  if (!countOfReviews) {
    return null;
  }

  return (
    <div className="flex items-center justify-between pb-6 sm:pb-0">
      <h1 className="text-main">{sumRating.toFixed(1)}</h1>
      <div className="pl-4">
        <Rating value={Math.round(sumRating)} size="large" />
        <p className="text-sm underline pt-1">
          Based on {countOfReviews} reviews
        </p>
      </div>
    </div>
  );
};

export default ReviewsSummary;

import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const BrandInfoCard = ({ data, totalProducts, description = '' }) => (
  <div className="w-full bg-white rounded-lg p-7 mb-7">
    <div className="before:bg-main p-0 pb-3 mb-4 relative flex bg-transparent items-center justify-between border-b border-gray before:absolute before:-bottom-0.5 before:left-0 before:h-0.5 before:w-12">
      <h5 className="mb-0 font-semibold uppercase">About {data.name}</h5>
    </div>
    <div className="text-center">
      <Link
        to={`/${data.slug}/`}
        className="relative justify-center pb-3 rounded"
      >
        <img
          src="/images/icon.png"
          alt={data.name}
          width={100}
          height={100}
          className="m-auto"
        />
      </Link>
      <div className="pb-5">
        <h4>
          <Link to={`/${data.slug}/`}>{data.name}</Link>
        </h4>
        <p></p>
      </div>
      <ul>
        <li className="flex items-center justify-between py-2 border-t border-gray-100 last:border-b">
          <h6>Total Products</h6>
          <p>{totalProducts}</p>
        </li>
      </ul>
    </div>
  </div>
);

export default BrandInfoCard;

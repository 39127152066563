import { Link } from 'gatsby';
import React from 'react';
import { Items, BlogPost } from '../types';

interface Props {
  items: Items<BlogPost>;
}

const SidebarBlogPosts: React.FC<Props> = ({ items }) => {
  if (items.nodes.length === 0) return null;

  const itemsList = React.useMemo(
    () =>
      items.nodes.map((item) => (
        <div
          key={item.data.slug}
          className="w-full bg-gray-100 border border-gray-200 rounded-lg mb-4"
        >
          <div className="px-2 py-1">
            <Link className="block px-2 py-2" to={`/blog/${item.data.slug}/`}>
              <h5 className="text-sm hover:underline">{item.data.title}</h5>
              <p className="text-xs italic text-gray-500 text-right pt-2">
                {item.data.publishedDate}
              </p>
            </Link>
          </div>
        </div>
      )),
    [items]
  );

  return (
    <>
      <h4 className="pt-6 pb-3">Related Posts</h4>
      {itemsList}
    </>
  );
};

export default React.memo(SidebarBlogPosts);

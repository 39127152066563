import React, { useEffect, useState, useRef, useCallback } from 'react';
import { SearchIcon } from '@heroicons/react/solid';
import useSiteMetadata from '../hooks/useSiteMetadata';
import * as JsSearch from 'js-search';
import { Link, navigate } from 'gatsby';

type Product = {
  name: string;
  brand: string;
  slug: string;
};

type SearchInputProps = {
  products: Product[];
};

const SearchInput = ({ products }: SearchInputProps) => {
  const { siteTitle, siteSubjectSlug } = useSiteMetadata();
  const [searchResults, setSearchResults] = useState<Product[]>([]);
  const [search, setSearch] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    const dataToSearch = new JsSearch.Search('name');
    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex('name');
    dataToSearch.addIndex('name');
    dataToSearch.addIndex('brand');
    dataToSearch.addDocuments(products); // adds the data to be searched

    setSearch(dataToSearch);
  }, [products]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!search) {
      return;
    }

    const queryResult = search.search(e.target.value);

    setSearchQuery(e.target.value);
    setSearchResults(queryResult);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const resetSearch = () => {
    setSearchQuery('');
    setSearchResults([]);
  };

  const handleKeys = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      navigate(`/${siteSubjectSlug}?k=${encodeURIComponent(searchQuery)}`);
    } else if (e.key === 'Escape') {
      resetSearch();
    }
  };

  const handleClickOutside = useCallback((e: MouseEvent) => {
    const searchBoxElem = document.getElementById('search-box');

    if (!searchBoxElem) {
      return;
    }

    // User clicked outside search result box, to close it
    if (!searchBoxElem.contains(e.target as Node)) {
      resetSearch();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <form onSubmit={handleSubmit} className="hidden w-full px-12 md:block">
      <div className="relative">
        <div className="flex bg-gray-100 rounded-lg">
          <button className="p-3" type="submit" title="Search Submit ">
            <SearchIcon className="w-5 h-5 text-gray-600 transition hover:text-main" />
          </button>
          <input
            value={searchQuery}
            onChange={handleSearch}
            onKeyDown={handleKeys}
            type="text"
            className="w-full py-3 bg-transparent border-none"
            placeholder={`Search ${siteTitle}`}
          />
        </div>
        {searchResults.length > 0 && (
          <div
            id="search-box"
            className="flex flex-col bg-white rounded-lg border absolute w-full z-20 overflow-y-auto max-h-64"
          >
            <div className="py-3">
              {searchResults.map((result, index) => (
                <Link
                  key={index}
                  tabIndex={index}
                  to={`/${result.slug}/`}
                  onClick={resetSearch}
                >
                  <h5 className="px-3 hover:bg-gray-100 py-2">{result.name}</h5>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default SearchInput;

import { graphql, useStaticQuery } from 'gatsby';
import { CustomPage, Items } from '../types';

interface useGetCustomPagesProps {
  allAirtable: Items<CustomPage>;
}

const useGetCustomPages = () => {
  // WARNING! "CustomPages" value is burned in, because unable to pass as param
  // CustomPages value = process.env.AIRTABLE_TABLE_CUSTOM_PAGES
  const data = useStaticQuery<useGetCustomPagesProps>(graphql`
    query FetchCustomPages {
      allAirtable(filter: { table: { eq: "CustomPages" } }) {
        nodes {
          data {
            slug
          }
        }
      }
    }
  `);

  const isCustomPageExists = (pageName: string) =>
    data.allAirtable.nodes.find((page) => page.data?.slug === pageName);

  return { items: data.allAirtable.nodes, isCustomPageExists };
};

export default useGetCustomPages;

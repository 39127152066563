import React from 'react';

interface Props {
  title?: string;
  children: React.ReactNode;
}

const Section: React.FC<Props> = ({ title = '', children }) => (
  <div className="w-full bg-white rounded-lg p-7 mb-7">
    {title && (
      <div className="before:bg-main p-0 pb-3 mb-4 relative flex bg-transparent items-center justify-between border-b border-gray before:absolute before:-bottom-0.5 before:left-0 before:h-0.5 before:w-12">
        <h2 className="mb-0 font-semibold uppercase text-lg">{title}</h2>
      </div>
    )}
    <div>{children}</div>
  </div>
);

export default Section;

import { Link } from 'gatsby';
import React from 'react';
import { Items, Category } from '../types';
import '../styles/showMore.css';
import slugify from '../utils/slugify';
interface Props {
  items: Items<Category>;
  title?: string;
}

const SidebarFilter: React.FC<Props> = ({ items, title = 'Filter' }) => {
  if (items.nodes.length === 0) return null;
  const filterNodeId = slugify(title);
  const itemsList = React.useMemo(
    () =>
      items.nodes.map((item) => (
        <li key={item.data.slug}>
          <Link
            className="block px-2 py-2 hover:underline"
            to={`/${item.data.slug}/`}
          >
            <h5 className="text-sm">{item.data.name}</h5>
          </Link>
        </li>
      )),
    [items]
  );

  const displayedItems = React.useMemo(
    () => (itemsList.length > 10 ? itemsList.splice(0, 10) : itemsList),
    [itemsList]
  );

  return (
    <div className="w-full bg-gray-100 border border-gray-200 rounded-lg mb-7">
      <div className="read-more px-3 pt-4 pb-3">
        <h6 className="pb-3 font-semibold uppercase border-b border-gray">
          {title}
        </h6>
        <input type="checkbox" className="read-more-state" id={filterNodeId} />
        <ul className="read-more-wrap py-2">
          {displayedItems}
          {items.nodes.length > 10 && (
            <span className="read-more-target">{itemsList}</span>
          )}
        </ul>
        {items.nodes.length > 10 && (
          <label
            htmlFor={filterNodeId}
            className="read-more-trigger py-1 w-full m-auto transition duration-300 bg-white rounded-lg hover:bg-main text-main hover:text-white"
          ></label>
        )}
      </div>
    </div>
  );
};

export default React.memo(SidebarFilter);

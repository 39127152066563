import React from 'react';

import { Footer, Header } from '../components';

interface Props {
  children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => (
  <div className="flex flex-col h-screen justify-between">
    <Header />
    <div className="flex-1">{children}</div>
    <Footer />
  </div>
);

export default Layout;

import { MenuIcon, XIcon } from '@heroicons/react/solid';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useRef } from 'react';
import useGetCustomPages from '../hooks/useGetCustomPages';

import useOnClickOutside from '../hooks/useOnClickOutside';
import useSiteMetadata from '../hooks/useSiteMetadata';
import useToggle from '../hooks/useToggle';
import '../styles/header.css';
import SearchInput from './SearchInput';

export const Header: React.FC = () => {
  // If you search for process.env.AIRTABLE_TABLE_NAME, make sure to update this query as well
  //  "Main" table name is burned in
  const data = useStaticQuery(graphql`
    {
      categories: allAirtable(
        filter: {
          table: { eq: "Hierarchy" }
          data: { type: { eq: "category" } }
        }
        sort: { data: { name: ASC } }
      ) {
        nodes {
          ...HierarchyFragmentData
        }
      }
      products: allAirtable(
        filter: { table: { eq: "Main" }, data: { slug: { ne: null } } }
      ) {
        nodes {
          data {
            name
            brand
            slug
          }
        }
      }
    }
  `);
  const { siteTitle, siteNavigation } = useSiteMetadata();
  const { isCustomPageExists } = useGetCustomPages();
  const [isOpen, setIsOpen] = useToggle(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpen(false));

  const desktopNav = siteNavigation.map((item, index) => {
    if (item.isCustomPage && !isCustomPageExists(item.slug.slice(1, -1))) {
      return null;
    }

    const dynamicSubMenu = data?.[item.submenu]?.nodes.length;
    const staticSubMenuItems = item.staticSubMenu?.filter((submenu) =>
      isCustomPageExists(submenu.slug.slice(1, -1))
    );
    const hasStaticSubMenu = staticSubMenuItems?.length;

    if (dynamicSubMenu || hasStaticSubMenu) {
      const dynamicSubMenuItems = data?.[item.submenu]?.nodes;

      return (
        <div key={`${item.slug}-${index}`} className="dropdown">
          <Link
            to={item.slug}
            className="font-medium text-gray-500 transition whitespace-nowrap"
          >
            {item.name}
          </Link>
          <nav className="z-20">
            <ul>
              {dynamicSubMenuItems?.map((submenu, index) => (
                <li key={`${submenu.data.slug}-${index}`}>
                  <Link to={`/${submenu.data.slug}/`}>{submenu.data.name}</Link>
                </li>
              ))}
              {staticSubMenuItems?.map((submenu, index) => (
                <li key={`${submenu.slug}-${index}`}>
                  <Link to={submenu.slug}>{submenu.name}</Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      );
    }
    return (
      <Link
        key={item.slug}
        to={item.slug}
        className="font-medium text-gray-500 transition whitespace-nowrap hover:underline"
      >
        {item.name}
      </Link>
    );
  });

  const mobileNav = siteNavigation.map((item, index) => {
    if (item.isCustomPage && !isCustomPageExists(item.slug.slice(1, -1))) {
      return null;
    }

    return (
      <Link
        key={`${item.slug}-${index}`}
        to={item.slug}
        className="flex items-center p-4 hover:bg-main hover:text-white"
      >
        {item.name}
      </Link>
    );
  });

  return (
    <header className="container py-3 md:py-5">
      <nav className="z-10 flex items-center justify-start w-full text-gray-700 bg-white md:justify-between">
        <div className="flex items-center">
          <div className="pr-4 md:hidden flex">
            <button
              type="button"
              className="px-3 text-gray-600 self-center"
              onClick={setIsOpen}
            >
              <MenuIcon className="flex justify-self-center w-6 h-6" />
            </button>
          </div>
          <div>
            <Link to="/" className="w-logo">
              <img
                src="/images/logo.png"
                alt={siteTitle}
                className="object-contain h-5 w-32 md:h-10 md:w-64"
              />
            </Link>
          </div>
        </div>
        <SearchInput products={data.products.nodes.map(({ data }) => data)} />
        <div className="items-center hidden md:flex">
          <div className="gap-4 md:flex md:justify-between md:bg-transparent">
            {desktopNav}
          </div>
        </div>
        {isOpen && (
          <div ref={ref} className="fixed inset-0 z-10 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        )}
        <aside
          className={`md:hidden container transform top-0 left-0 w-full bg-white fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30 ${
            isOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
        >
          <span className="flex items-center w-full py-4 border-b">
            <button
              className="px-3 pr-7 text-gray-600 rounded-full"
              onClick={setIsOpen}
            >
              <XIcon className="w-6 h-6" />
            </button>
            <Link to="/">
              <img
                src="../images/logo.png"
                alt={siteTitle}
                width={260}
                height={40}
              />
            </Link>
          </span>
          {mobileNav}
        </aside>
      </nav>
    </header>
  );
};

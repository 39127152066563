import React from 'react';

type ProgressBarProps = {
  value: number;
};

const ProgressBar = ({ value }: ProgressBarProps) => (
  <div className="w-full bg-gray-200 h-2.5 dark:bg-gray-700">
    <div className="bg-main h-2.5" style={{ width: `${value}%` }}></div>
  </div>
);

export default ProgressBar;

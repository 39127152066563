export const getPaginationState = ({
  currentPage,
  numPages,
  basePath,
  pagePath,
}) => ({
  base: `/${basePath}/`,
  page: pagePath,
  currentPage,
  numPages,
  isFirst: currentPage === 1,
  isLast: currentPage === numPages,
  prevPage:
    currentPage - 1 === 1
      ? `/${basePath}/`
      : `/${basePath}/${pagePath}${currentPage - 1}/`,
  prevNumPage: currentPage - 1,
  nextPage: `/${basePath}/${pagePath}${currentPage + 1}/`,
  nextNumPage: currentPage + 1,
  nextNextNumPage: currentPage + 2,
});

import React from 'react';
import { Reviews } from '../../types';
import ProgressBar from '../ProgressBar';
import Rating, { FullStar } from '../Rating';
import ReviewsSummary from './ReviewsSummary';

interface Props {
  reviews: Reviews;
}

const DetailedReviews: React.FC<Props> = ({ reviews }) => {
  const countOfReviews = reviews.nodes.length;

  const getRatingNumPercentage = (rating: number) =>
    (reviews.nodes.filter((review) => Math.floor(review.data.rating) === rating)
      .length /
      countOfReviews) *
    100;

  if (!countOfReviews) {
    return null;
  }

  const displayReviewProgresses = React.useMemo(
    () =>
      new Array(5)
        .fill(null)
        .map((_, starIndex) => starIndex + 1)
        .reverse()
        .map((starIndex) => (
          <div className="flex items-center" key={starIndex}>
            {starIndex}
            <div className="pb-1 pr-4">
              <FullStar size="medium" />
            </div>
            <ProgressBar value={getRatingNumPercentage(starIndex)} />
          </div>
        )),
    [reviews, countOfReviews]
  );

  return (
    <div className="flex flex-col sm:flex-row items-center justify-between p-6">
      <ReviewsSummary reviews={reviews} />
      <div className="w-3/6">{displayReviewProgresses}</div>
    </div>
  );
};

export default DetailedReviews;

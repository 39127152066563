import React from 'react';
import { BlogPost, Items } from '../../types';
import BlogCard from './BlogCard';

interface Props {
  blogPosts: Items<BlogPost>;
}

const BlogPostsLatest: React.FC<Props> = ({ blogPosts }) => {
  if (blogPosts.nodes.length === 0) return null;

  const productsList = blogPosts.nodes.map((blogPost, index) => (
    <div key={index}>
      <BlogCard item={blogPost} />
    </div>
  ));

  return (
    <section className="container pb-28">
      <h2 className="pb-10 text-center">
        Latest <span className="text-main">Posts</span>
      </h2>
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:gap-7 ">
        {productsList}
      </div>
    </section>
  );
};

export default BlogPostsLatest;

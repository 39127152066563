import { Link } from 'gatsby';
import React from 'react';
import { Comparison } from '../../types';

interface Props {
  comparisons: ReadonlyArray<Comparison>;
  name?: string;
}

const ProductComparisonWidget: React.FC<Props> = ({ comparisons, name }) => {
  if (comparisons.length === 0) return null;

  const [showList, setShowList] = React.useState('hidden');
  const toggleDisplay = () =>
    setShowList(showList === 'hidden' ? 'block' : 'hidden');

  const itemsList = comparisons.map((item) => (
    <li key={item.data.slug}>
      <Link
        className="block px-2 py-2 hover:underline"
        to={`/${item.data.slug}/`}
      >
        <h5 className="text-sm">{item.data.name}</h5>
      </Link>
    </li>
  ));

  const displayedItems =
    itemsList.length > 10 ? itemsList.splice(0, 10) : itemsList;

  return (
    <div className="w-full bg-white rounded-lg p-7 mb-7">
      <div className="before:bg-main p-0 pb-3 mb-4 relative flex items-center justify-between border-b border-gray before:absolute before:-bottom-0.5 before:left-0 before:h-0.5 before:w-12">
        <h5 className="mb-0 font-semibold uppercase">
          {name ? `Compare the ${name}` : 'Comparisons'}
        </h5>
      </div>
      <ul className="py-2">
        {displayedItems}
        {itemsList.length > 10 && <span className={showList}>{itemsList}</span>}
      </ul>
      {itemsList.length > 10 && (
        <div className="pt-3 border-t border-gray">
          {showList === 'hidden' ? (
            <button
              className="w-full py-2 font-medium transition duration-300 bg-white rounded-lg px-9 hover:bg-main text-main hover:text-white"
              onClick={toggleDisplay}
            >
              View more
            </button>
          ) : (
            <button
              className="w-full py-2 font-medium transition duration-300 bg-white rounded-lg px-9 hover:bg-main text-main hover:text-white"
              onClick={toggleDisplay}
            >
              View less
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductComparisonWidget;

import { Link } from 'gatsby';
import React from 'react';
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from '@heroicons/react/solid';

interface Props {
  data: any;
}

const BlogPostNavigation: React.FC<Props> = ({ data }) => {
  return (
    <div className="pb-8 flex justify-center">
      {data.prev && (
        <Link
          to={`/blog/${data.prev.slug}/`}
          className="bg-white px-4 py-2 text-main rounded-lg mr-2 content-center hover:bg-main hover:text-white transition"
        >
          <ArrowNarrowLeftIcon className="pr-2 inline w-6" />
          {data.prev.title}
        </Link>
      )}
      {data.next && (
        <Link
          to={`/blog/${data.next.slug}/`}
          className="bg-white px-4 py-2 text-main rounded-lg ml-2 content-center hover:bg-main hover:text-white transition"
        >
          {data.next.title}
          <ArrowNarrowRightIcon className="pl-2 inline w-6 " />
        </Link>
      )}
    </div>
  );
};

export default BlogPostNavigation;

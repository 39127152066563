import React from 'react';
import { CustomPage } from '../../types';
import { parse } from 'node-html-parser';

interface Props {
  customPage: CustomPage;
}

const CustomPageSection: React.FC<Props> = ({ customPage }) => {
  const pageContent = React.useMemo(
    () =>
      customPage?.data?.fullDescription?.childMarkdownRemark?.html
        ? parse(customPage.data.fullDescription.childMarkdownRemark.html)
            .innerHTML
        : '',
    [customPage]
  );

  if (!pageContent) {
    return null;
  }

  return (
    <section className="container pt-28">
      <div
        dangerouslySetInnerHTML={{
          __html: pageContent,
        }}
      />
    </section>
  );
};

export default CustomPageSection;

import React from 'react';
import { Section } from '../../components';
import { Purchases } from '../../types';
import slugify from '../../utils/slugify';
import '../../styles/showMore.css';
import ProductPurchaseSummary from './ProductPurchaseSummary';

const INITAL_ITEM_COUNT = 5;

interface Props {
  purchases: Purchases;
  productName: string;
}

const ProductPurchase: React.FC<Props> = ({ productName, purchases }) => {
  if (purchases.nodes.length === 0) return null;

  const sectionTitle = `Where to buy the ${productName}`;
  const filterNodeId = slugify(sectionTitle);
  const isShowMoreBtnVisible = purchases.nodes.length > INITAL_ITEM_COUNT;
  const purchasesList = purchases.nodes.map((item, index) => (
    <div
      key={index}
      className={[
        'items-center justify-between px-4 py-3 mb-6 bg-gray-100 border rounded-lg md:px-8 md:py-6 md:flex',
        isShowMoreBtnVisible ? '' : 'last:mb-0',
      ].join(' ')}
    >
      <div className="md:w-3/6">
        <h6>{item.data.title}</h6>
        <div className="text-xs">{item.data.site}</div>
      </div>
      {item.data.totalPrice && (
        <div className="py-2 md:text-center md:w-1/6">
          ${item.data.totalPrice}
        </div>
      )}
      <div className="md:text-right md:w-2/6">
        <a
          target="_blank"
          rel="nofollow"
          className="block py-2 font-bold text-center text-white uppercase transition border-2 rounded-lg md:inline md:py-4 whitespace-nowrap px-7 bg-main hover:bg-white hover:text-main border-main"
          href={item.data.url}
        >
          Check Price
        </a>
      </div>
    </div>
  ));

  const displayedPurchases = purchasesList.slice(0, INITAL_ITEM_COUNT);
  const showMorePurchases = purchasesList.slice(INITAL_ITEM_COUNT);

  return (
    <Section title={sectionTitle}>
      <ProductPurchaseSummary productName={productName} purchases={purchases} />
      <div className="read-more">
        <div>{displayedPurchases}</div>
        <input type="checkbox" className="read-more-state" id={filterNodeId} />
        <div className="read-more-wrap">
          <span className="read-more-target">{showMorePurchases}</span>
        </div>
        {showMorePurchases.length > 0 && (
          <label
            htmlFor={filterNodeId}
            className="read-more-trigger w-full py-1 px-9 m-auto transition duration-300 bg-white rounded-lg hover:bg-main text-main hover:text-white"
          />
        )}
      </div>
    </Section>
  );
};

export default ProductPurchase;
